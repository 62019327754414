<template>
  <div class="category-page">
    <div class="title">{{ $t('fen-lei') }}</div>
    <div class="category-list">
      <div
        class="list flex"
        v-for="(item, i) in categoryList"
        :key="i"
        @click="toGoodsList(item)"
      >
        <div class="img">
          <img :src="item.Image || categoryUrl" alt="" />
        </div>
        <div class="flex-1 info">
          <div class="name">{{ item.title }}</div>
          <div class="label">
            <template v-for="(subItem, index) in item.children">
              <span
                v-for="(category, m) in subItem.children"
                :key="`category${i}-${index}-${m}`"
                @click.stop="toGoodsList(category)"
              >
                {{ category.title }}
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCategoryList } from '@/api/shop'
export default {
  data() {
    return {
      categoryUrl: require('@/assets/imgs/category1.png')
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
    categoryList() {
      return this.$store.state.categoryList
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('initCategory', this.lang)
      this.$nextTick(() => {}, 100)
    },
    toGoodsList(data) {
      this.$router.push({
        name: 'commodity',
        query: {
          id: data.ID
        }
      })
    }
  }
}
</script>